<!-- 专区落地页 6:中药，7：医保，8：诊所，9：医院，10：独家，1：我的计划，2：返利，3：红包 -->
<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>
        <div class="v2-g-w1200">
            <div class=" title1 v2-g-flex-row  v2-g-flex-space-between" v-if="$route.params.id=='2' || $route.params.id=='3' ">
                <div><img src="@/assets-v2/images/zuoxian.png" /></div>
                <div><img src="@/assets-v2/images/dh_lingxing.png" /></div>
                <div>
                    <span v-if="$route.params.id=='2'">我 / 的 / 返 / 利</span>
                    <span v-if="$route.params.id=='3'">新 / 品 / 上 / 市</span>
                </div>
                <div><img src="@/assets-v2/images/dh_lingxing.png" /></div>
                <div><img src="@/assets-v2/images/zuoxian.png" /></div>
            </div>

            <!--首页分类显示筛选条件-->
            <div class="head" v-if="id == 20">
                <div class="condition v2-g-flex-row">
                    <p class="title">已筛选条件:</p>
                    <div class="v2-g-flex-row">
                        <span></span>
                        <div class="sel-condition v2-g-flex-row">
                            <div class="con con1" v-if="queryParam.categoryList.length > 0">
                                <span v-for="(item, index) in queryParam.categoryList" :key="item.catalogName">
                                  {{ item.catalogName }}
                                  <template v-if="index < queryParam.categoryList.length - 1">、</template>
                                </span>
                                <i class="iconfont icon-reeor" @click="clearTheProductCategory"></i>
                            </div>

                            <div class="con" v-if="queryParam.yibaoList.length > 0">
                                <span v-for="(item, index) in queryParam.yibaoList" :key="item.id + '1'">
                                  {{ item.name }}
                                  <template v-if="index < queryParam.yibaoList.length - 1">、</template>
                                </span>
                                <i class="iconfont icon-reeor" @click="queryParam.yibaoList=[]; productList = allGoodsList;"></i>
                            </div>

                            <div class="con con3" v-if="queryParam.formulationList.length > 0">
                                <span v-for="(item, index) in queryParam.formulationList" :key="item.key + '1'">
                                  {{ item.value }}
                                  <template v-if="index < queryParam.formulationList.length - 1">、</template>
                                </span>
                                <i class="iconfont icon-reeor" @click="queryParam.formulationList=[];medicalInsuranceGoodList()"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="condition cpfl v2-g-flex-row">
                    <p>产品类别</p>
                    <div class="content v2-g-flex-row">
                      <span class="category" @mouseover="mouseOver(index)"
                            @mouseleave="mouseLeave(index)" :class="index == houverIndex ? 'sel' : ''"
                            v-for="(item, index) in copyCategoryList" :key="item.id + '1'" @click="selCategory(item,1)">
                        <input type="checkbox" class="multi-sel" v-if="categroyMultiSel" v-model="item.isSel" />
                        {{ item.catalogName }}<i class="iconfont icon-arrow-down"></i>
                      </span>

                      <!--产品类别hove时，显示二级/三级分类-->
                      <div>
                        <div class="sec" v-for="(item, index) in copyCategoryList"
                             :class="{ 'sel': index == houverIndex }" :key="item.id + '10'"
                             @mouseover="mouseOver(index)"
                             @mouseleave="mouseLeave(index)">
                          <template v-for="item1 of item.childList">
                            <p class="second" @click="selCategory(item1,2)">
                              <input type="checkbox" class="multi-sel" v-if="categroyMultiSel" v-model="item1.isSel" />
                              {{ item1.catalogName }}
                            </p>
                            <div class="third v2-g-flex-row" v-if="item1.childList!=null">
                              <span v-for="item2 of item1.childList" @click="selCategory(item2,3)">
                                <input type="checkbox" class="multi-sel" v-if="categroyMultiSel" v-model="item2.isSel" />
                                {{ item2.catalogName }}
                              </span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="op">
                      <span v-if="!categroyMultiSel" @click="theMultiSelectStatusIsDisplayed('categroyMultiSel')">
                        <i class="iconfont icon-add-select"></i>多选
                      </span>
                      <span class="ok" v-if="categroyMultiSel" @click="selCategoryOk">确定</span>
                      <span class="cancel" v-if="categroyMultiSel" @click="categroyMultiSel = false">取消</span>
                    </div>
                </div>

                <div class="condition v2-g-flex-row">
                    <p>医保</p>
                    <div class="content v2-g-flex-row">
                         <span v-for="item of yibaoList" @click="selYibao(item)">
                           <input type="checkbox" class="multi-sel" v-if="yibaoMultiSel" v-model="item.isSel" />
                           {{item.name}}
                         </span>
                    </div>
                    <div class="op">
                      <span v-if="!yibaoMultiSel" @click="yibaoMultiSel = true"><i class="iconfont icon-add-select"></i>多选</span>
                      <span class="ok" v-if="yibaoMultiSel && yibaoList.findIndex(t=>t.isSel)>=0" @click="selYibaoOk">确定</span>
                      <span class="cancel" v-if="yibaoMultiSel" @click="yibaoMultiSel = false">取消</span>
                    </div>
                </div>

                <div class="condition v2-g-flex-row formulation" :class="isMore?'more':''">
                    <p>剂型</p>
                    <div class="content v2-g-flex-row">
                      <span v-for="item of copyFormulationList" @click="selFormulation(item)">
                        <input type="checkbox" class="multi-sel" v-if="jixingMultiSel" v-model="item.isSel"/>
                        {{ item.value }}
                      </span>
                    </div>
                    <div class="op">
                        <span @click="isMore = !isMore">
                            <template v-if="!isMore">更多<i class="iconfont icon-arrow-down"></i></template>
                            <template v-else>收起<i class="iconfont icon-arrow-up"></i></template>
                        </span>
                        <span v-if="!jixingMultiSel" @click="jixingMultiSel = true">
                          <i class="iconfont icon-add-select"></i>多选
                        </span>
                        <span class="ok" v-if="jixingMultiSel && copyFormulationList.findIndex(t=>t.isSel)>=0"
                            @click="selFormulationOk">确定</span>
                        <span class="cancel" v-if="jixingMultiSel" @click="jixingMultiSel = false">取消</span>
                    </div>
                </div>
            </div>

            <ProductList :list="productList" :id="id" :pageInfo="pageInfo" v-on:getdata="getDataByPage" @getGoodsList="getData" />
        </div>
        <PageBottom></PageBottom>
    </div>
</template>

<style scoped lang ="less">
.head {
    border-top: 1px solid #EDEDED;
}
.title1 {
    margin: 30px auto;
    height: 40px;

    line-height: 40px;
    font-size: 16px;
    font-weight: 400;


    span {
        color:#666;
        font-size: 1.75rem;
    }

}
.condition {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #EDEDED;
    input[type='checkbox']{
        width: 1.1rem;
    height: 1.1rem;
    vertical-align: middle;
    margin-right: 5px;
    }

    .sel-condition {
        flex-wrap: wrap;


        .con {
            background: #F1F6FF;
            border-radius: 12px;
            margin: 15px 5px 0 5px;
            color: #3875F6;
            padding: 0px 20px 0 10px;
            font-size: 0.8rem;
            height: 25px;
            line-height: 25px;
            position: relative;

            i {
                position: absolute;
               right: 0;
               top:-5px;
                font-size: 1.25rem;
                vertical-align: bottom;
            }
            white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            &.con1{
                max-width: 500px;

            }
            &.con3{
                max-width:300px;
            }
        }
    }

    &.cpfl {
        height: 70px;
        line-height: 70px;

        .multi-sel {
            vertical-align: middle;
            margin-right: 5px;
        }

        .content {
            position: relative;

            span {
                margin: 0px 10px;
                cursor: pointer;

                &.sel {
                    border: 1px solid grey;
                    border-bottom: none;
                    z-index: 15;
                    background-color: #fff;
                    color: #333333;
                }
            }

            .sec {
                display: none;
                position: absolute;
                top: 29px;

                &:nth-child(9) {
                    top: 64px;
                }

                left:0;
                width: 900px;

                box-shadow:1px 1px 1px rgb(153 153 153 / 30%);
                z-index: 11;
                border:1px solid grey;
                background-color: #fff;

                &.sel {
                    display: block;

                }

                p {
                    line-height: 20px;
                    text-align: left;
                    margin: 5px 10px;
                }
                &:nth-child(7), &:nth-child(8){
                    text-align: left;

                     .second{
                        display: inline;
                     }
                }
                .second {
                    cursor: pointer;
                    width: 100%;
                    &:hover{
                        color:red;
                    }

                }

                .third {
                    flex-wrap: wrap;

                    span {
                        margin: 0 10px;

                        &:hover {
                            background: none;
                            color: red;
                        }
                    }
                }


            }
        }

    }
    &.formulation{
        overflow: hidden;
    }
    &.more{
        height: 200px;
    }
    p {
        font-weight: 600;
        width: 100px;
        font-size: .8rem;
        text-align: left;

    }

    .title {
        font-size: 1rem;
        line-height: 50px;
    }

    .content {
        flex-wrap: wrap;
        width: 1000px;
        height: 100%;
        span {
            background-color: #fff;
            color: #333333;
            border: 1px solid #fff;
            font-weight: 400;
            margin: 10px 5px 0 5px;
            cursor: pointer;
            height: 30px;

            line-height: 30px;
            padding: 0 5px;

            &.sel,
            &:hover {
                background: none;
                color: red;

            }

            &.category {
                i {

                    font-weight: 600;
                }

            }

        }
    }

    .op {
        z-index: 100;
        width: 200px;
        span {
            border-radius: 4px;
            font-size: 14px;
            color: #333333;
            background: #e1e1e1;
            padding: 0px 6px;
            cursor: pointer;
            display: inline-block;
            transform: scale(0.75);
            line-height: 26px;

            &:hover {
                color: red;
            }

            &.ok {
                background-color: #167df0;
                color: #fff;
                padding: 2px 10px;
            }

            &.cancel {
                padding: 2px 10px;
            }
        }

    }
}
</style>

<script>
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"

import ProductList from "@/components-v2/product/product-list";
import { mapActions, mapGetters } from "vuex";
import {kv} from "../../mixin/kv"
import { arrUnique, purchaseQuantityProcessing } from '@/utils'

export default {
    mixins:[kv],
    data() {
        return {
            id:this.$route.params.id,
            kvCode:0,//埋点代码
            pageAttr:{
                name:'',
                url:''
            }, //专区页面属性
            houverIndex: -1, //分类产品，鼠标指向的一级类别
            categroyMultiSel: false, //分类多选控制器
            yibaoMultiSel: false,//医保多选控制器
            jixingMultiSel: false,//剂型多选控制器
            copyCategoryList: [],//复制的分类数据，
            copyFormulationList:[],//复制的剂型数据
            yibaoList:[
              {id: "1", name: '甲', isSel: false},
              {id: "2", name: '乙', isSel: false},
              {id: "3", name: '丙', isSel: false}
            ],
            tempSelCategory: [], //临时存放 分类条件多选值
            isMore:false, //剂型显示更多
            queryParam: {
              searchCriteria: "",
              categoryList: [],  //分类条件
              yibaoList: [],
              formulationList: [],
              code: "",
              activity: '',
              page: 1,//请求页
              pageSize: 20
            },
            productList: [], //数据
            pageInfo: {
              total: 0,
              pageNum: 1,
              pageSize: 20
            },
            medicalClass:0,
            insuranceCode:0,
            medicalClassSort:0,
            allGoodsList: [],  // 保存所有分类数据
            lstFirstCatagoryId: '',  // 保存一级分类id
            temporaryGoodList: [],  // 分类筛选后的数据
        }
    },
    components: {PageTop, PageTop1, PageBottom, ProductList},
    created() {
        this.getPageAttr();
        this.getCondition();//获取分类
        this.kv_visit(this.kvCode)
    },
    computed: {
        ...mapGetters({
            categoryList: 'categoryList',
            formulationList:'formulationList',
            actBal:'actBal'
        })
    },
    watch:{
      '$route.params': function () {
        this.queryParam = {
          productName: "",
          categoryList: [],  //分类条件
          yibaoList: [],
          formulationList: [],
          code: "",
          pageSize: 20,
          activity: '',
          page: 1
        };
        this.getPageAttr();
        this.kv_visit(this.kvCode);
        this.getCondition();//获取分类
      }
    },
    methods: {
        ...mapActions([
            'getCategoryLst',
            'getFormulationLst',
            'getActBal'
        ]),

        getCondition() {
            let $this = this;
            if (this.categoryList.length == 0) {
                this.getCategoryLst().then(() => {
                    this.handleCopyCategoryList()
                });
            } else {
                this.handleCopyCategoryList()
            }
            //剂型
            if (this.formulationList.length == 0) {
              this.getFormulationLst().then(() => {
                $this.copyFormulationList = [...$this.formulationList];
                $this.copyFormulationList.forEach(ele => {
                  $this.$set(ele, 'isSel', false);
                })
              });
            } else {
              $this.copyFormulationList = [...$this.formulationList];
              $this.copyFormulationList.forEach(ele => {
                $this.$set(ele, 'isSel', false);
              })
            }
        },
        setUpOneLevelId(obj, currentId) {
          obj.oneLevelId = currentId;
          if (obj.childList) {
            obj.childList.forEach(child => this.setUpOneLevelId(child, currentId));
          }
        },
        // 处理筛选分类数据
        handleCopyCategoryList() {
          let arr = [...this.categoryList];
          arr.forEach(ele => {
            ele.isSel = false;
            if (ele.level == 1) {
              ele.oneLevelId = ele.id;
              this.setUpOneLevelId(ele, ele.id);
            }
          })
          this.copyCategoryList = arr;

          // 如果是分类页面，初始化分类的一级id
          if (this.$route.params.id == '20') {
            let obj = {
              ...this.$route.query
            }
            let id = this.$route.query.id;
            for (let i = 0; i < this.copyCategoryList.length; i++) {
              if (this.copyCategoryList[i].id == id) {
                obj.oneLevelId = this.copyCategoryList[i].id;
                break;
              }
              if (this.copyCategoryList[i].childList) {
                for (let j = 0; j < this.copyCategoryList[i].childList.length; j++) {
                  if (this.copyCategoryList[i].childList[j].id == id) {
                    obj.oneLevelId = this.copyCategoryList[i].id;
                    break;
                  }

                  if (this.copyCategoryList[i].childList[j].childList) {
                    for (let k = 0; k < this.copyCategoryList[i].childList[j].childList.length; k++) {
                      if (this.copyCategoryList[i].childList[j].childList[k].id == id) {
                        obj.oneLevelId = this.copyCategoryList[i].id;
                        break;
                      }
                    }
                  }
                }
              }
            }
            this.lstFirstCatagoryId = this.copyCategoryList[0].id;
            this.queryParam.categoryList.push(obj);
          }
          this.initData();
        },
        getPageAttr() {
            let id = this.$route.params.id.toString();
            switch (id) {
                case '1':
                    this.pageAttr = {
                        name:'我的计划',
                        url:"/pjyy-deepexi-product-center/api/v2/product/PcProductCommon/selectCommonProductList"
                    };
                    this.kvCode=14;
                    break;
                case '2':
                    this.pageAttr = {
                        name:'返利专区',
                        url:"/pjyy-deepexi-product-center/api/v3/product/PcProductCommon/selectZqProductPage"
                    };
                    this.kvCode=3;
                    break;
                case '3':
                    this.pageAttr = {
                        name:'新品上市',
                        url:"/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/newProduct"
                    };
                    this.kvCode=15;
                    break;
                case '6':
                    this.pageAttr = {
                        name:'中药专区',
                        url:"/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/chineseMedicine"
                    };
                    this.kvCode=7;
                    break;
                case '7':
                    this.pageAttr = {
                        name:'医保专区',
                        url:"/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/medicalInsurance"
                    };
                    this.kvCode=8;
                    break;
                case '8':
                    this.pageAttr = {
                        name:'诊所专区',
                        url:"/pjyy-deepexi-product-center/api/v3/product/PcProductCommon/selectZqProductPage"
                    };
                    this.kvCode=9;
                    break;
                case '9':
                    this.pageAttr = {
                        name:'诊卫专区',
                        url:"/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/clinicAndHealthRoom"
                    };
                    this.kvCode=17;
                    break;
                case '10':
                    this.pageAttr = {
                        name:'独家专区',
                        url:"/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/exclusive"
                    };
                    this.kvCode=11;
                    break;
                case '20':
                    this.pageAttr = {
                        name: '分类',
                        url: '/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/catalog'
                    };
                    this.kvCode=6;
                    break;
                }
        },
        //分类产品 鼠标放上去看下级
        mouseOver(index) {
            this.houverIndex = index;
        },
        mouseLeave() {
            this.houverIndex = -1;
        },
        /**
         * @param {*} item
         * @param {*} levelType  1:第一级，2:第二级，3：第三级
         */
        selCategory(item, levelType) {
          this.queryParam.page = 1;
          // 多选
          if (this.categroyMultiSel) {
            item.isSel = !item.isSel;

            if (levelType == 1) {
              item.childList.forEach(node => {
                node.isSel = item.isSel
                if (node.childList && Array.isArray(node.childList)) {
                  node.childList.forEach(level3Node => level3Node.isSel = node.isSel)
                }
              })
            } else if (levelType == 2) {
              if (item.childList && Array.isArray(item.childList)) {
                item.childList.forEach(node => node.isSel = item.isSel)
              }

              this.copyCategoryList.forEach(level1Node => {
                level1Node.childList.forEach(level2Node => {
                  if (level2Node.catalogCode === item.catalogCode) {
                    // 过滤当前一级分类下，选择的二级分类
                    let arr = level1Node.childList.filter(t => t.isSel == true)
                    // 如果二级分类都选中，则一级分类也选中；如果二级分类部分选中，则一级分类不选中
                    level1Node.isSel = arr.length === level1Node.childList.length;
                  }
                })
              });
            } else if (levelType == 3) {
              this.copyCategoryList.forEach(level1Node => {
                level1Node.childList.forEach(level2Node => {
                  if (level2Node.childList) {
                    level2Node.childList.forEach(level3Node => {
                      if (level3Node.catalogCode === item.catalogCode) {
                        // 过滤当前二级分类下，选择的三级分类
                        let arr = level2Node.childList.filter(t => t.isSel == true)
                        // 如果三级分类都选中，则二级分类也选中；如果三级分类部分选中，则二级分类不选中
                        level2Node.isSel = arr.length === level2Node.childList.length;
                      }
                    })
                  }

                  // 过滤当前一级分类下，选择的二级分类
                  let arr = level1Node.childList.filter(t => t.isSel == true)
                  // 如果二级分类都选中，则一级分类也选中；如果二级分类部分选中，则一级分类不选中
                  level1Node.isSel = arr.length === level1Node.childList.length;
                })
              });
            }
            this.$forceUpdate()
          } else {  // 单选
            let copy = {
              id: item.id,
              catalogName: item.catalogName,
              catalogCode: item.catalogCode,
              type: item.level,
              oneLevelId: item.oneLevelId
            };
            this.queryParam.categoryList = [copy];
            this.initData()

            // this.lstFirstCatagoryId = item.oneLevelId;
            // let key;
            // if (item.level == 2) {
            //   key = 'lstSecondCatagoryId';
            // } else if (item.level == 3) {
            //   key = 'lstThirdCatagoryId';
            // }
            // this.localFilteringGoodsList(key, item)
          }
        },
        // 产品类别-单选处理逻辑
        localFilteringGoodsList(key, item) {
          let copy = {
            id: item.id,
            catalogName: item.catalogName,
            catalogCode: item.catalogCode,
            type: item.level,
            oneLevelId: item.oneLevelId
          };
          this.queryParam.categoryList = [copy];

          // 如果选择的分类是同一级的子类  本地筛选商品数据
          if (this.lstFirstCatagoryId == this.allGoodsList[0].lstFirstCatagoryId) {
            let arr = this.allGoodsList.filter(t => t[key] == item.id)
            this.productList = arr.splice(0, 10);
            this.temporaryGoodList = this.productList;
          } else {
            // 选择的分类非同一级的子类，重新请求数据
            this.getData();
          }
        },
        // 产品类别-多选，把临时区数据添加到真正数据区
        selCategoryOk() {
          this.queryParam.page = 1;
          let oneCategoryCode = [], twoCatalogCodes = [], threeCatalogCodes = [], arr = [];

          this.copyCategoryList.forEach(level1Node => {
            if (level1Node.isSel) {
              oneCategoryCode.push(level1Node.catalogCode)
              arr.push({
                id: level1Node.id,
                catalogName: level1Node.catalogName,
                catalogCode: level1Node.catalogCode,
                type: level1Node.level,
                oneLevelId: level1Node.oneLevelId
              })
            } else {
              level1Node.childList.forEach(level2Node => {
                if (level2Node.isSel) {
                  // handleCatalogCodesData(level2Node)
                  twoCatalogCodes.push(level2Node.catalogCode)
                  arr.push({
                    id: level2Node.id,
                    catalogName: level2Node.catalogName,
                    catalogCode: level2Node.catalogCode,
                    type: level2Node.level,
                    oneLevelId: level2Node.oneLevelId
                  })
                } else {
                  if (level2Node.childList) {
                    level2Node.childList.forEach(level3Node => {
                      if (level3Node.isSel) handleThreeCatalogCodes(level3Node)
                    })
                  }
                }
              })
            }
          });

          function handleCatalogCodesData(node) {
            if (node.childList) {
              node.childList.forEach(level3Node => {
                handleThreeCatalogCodes(level3Node)
              })
            } else {
              twoCatalogCodes.push(node.catalogCode)
              arr.push({
                id: node.id,
                catalogName: node.catalogName,
                catalogCode: node.catalogCode,
                type: node.level,
                oneLevelId: node.oneLevelId
              })
            }
          }

          function handleThreeCatalogCodes(level3Node) {
            threeCatalogCodes.push(level3Node.catalogCode)
            arr.push({
              id: level3Node.id,
              catalogName: level3Node.catalogName,
              catalogCode: level3Node.catalogCode,
              type: level3Node.level,
              oneLevelId: level3Node.oneLevelId
            })
          }

          this.tempSelCategory = arr;
          this.tempSelCategory = arrUnique(this.tempSelCategory, 'id')

          this.queryParam.categoryList = [...this.tempSelCategory];
          this.categroyMultiSel = false;

          this.getData(oneCategoryCode, twoCatalogCodes, threeCatalogCodes);
        },

        // 医保-单选
        selYibao(item) {
          if (this.yibaoMultiSel) {
            item.isSel = !item.isSel;
          } else {
            this.queryParam.yibaoList = [item];
            this.medicalInsuranceGoodList();
          }
        },
        // 医保-多选
        selYibaoOk() {
          this.queryParam.yibaoList = this.yibaoList.filter(t => t.isSel);
          this.yibaoMultiSel = false;
          this.medicalInsuranceGoodList();
        },

        // 剂型-单选
        selFormulation(item) {
          if (this.jixingMultiSel) {
            item.isSel = !item.isSel;
          } else {
            this.queryParam.formulationList = [item];
            this.medicalInsuranceGoodList();
          }
        },
        // 剂型-多选
        selFormulationOk() {
          this.queryParam.formulationList = this.copyFormulationList.filter(t => t.isSel);
          this.jixingMultiSel = false;
          this.medicalInsuranceGoodList()
        },

        // 筛选分类后的 医保/剂型数据
        medicalInsuranceGoodList() {
          let arr = [];
          if (this.queryParam.yibaoList.length) {
            this.temporaryGoodList.forEach(item => {
              this.queryParam.yibaoList.forEach(t => {
                if (item.category == t.id) arr.push(item)
              })
            })
          }
          if (this.queryParam.formulationList.length) {
            arr = [];
            this.temporaryGoodList.forEach(item => {
              this.queryParam.formulationList.forEach(t => {
                if (item.formulation == t.key) {
                  if (this.queryParam.yibaoList.length && item.category) {
                    arr.push(item)
                  } else {
                    arr.push(item)
                  }
                }
              })
            })
          }
          this.productList = arr;
        },

        // 清空产品类别
        clearTheProductCategory() {
          this.queryParam.categoryList = [];

          function clearCategoryList(nodes) {
            for (const node of nodes) {
              node.isSel = false;
              if (node.childList && Array.isArray(node.childList)) {
                clearCategoryList(node.childList);
              }
            }
          }

          clearCategoryList(this.copyCategoryList)

          this.getData();
        },

        // 回显多选状态
        theMultiSelectStatusIsDisplayed(key) {
          this[key] = true;
          if (key === 'categroyMultiSel') {
            this.traverseNodes(this.copyCategoryList)
          }
        },

        traverseNodes(nodes) {
          for (const node of nodes) {
            node.isSel = false
            // 查找当前节点是否在选中列表中
            const isSelected = this.queryParam.categoryList.some(selected => selected.id == node.id);

            // 如果选中，则设置 isSel 为 true
            if (isSelected) node.isSel = true;

            if (node.childList && Array.isArray(node.childList)) {
              // 过滤当前节点已选中的子节点
              let arr = node.childList.filter(t => t.isSel == true)
              // 如果当前节点的所有子节点都被选中，则设置 isSel 为 true
              node.isSel = arr.length === node.childList.length;

              this.traverseNodes(node.childList);
            }
          }
        },

        /**
         * 获取商品分类的二级或三级分类的 catalogCode 的规则：
         * 1、如果选择的是一级分类，就获取一级分类下的所有三级分类的 catalogCode
         * 2、如果选择的是二级分类，就获取三级分类的 catalogCode
         * 3、如果选择的是三级分类，就获取三级分类的 catalogCode
         * 4、如果只有二级分类，只获取二级分类的 catalogCode
         */
        initData() {
          let oneCategoryCode = [], twoCatalogCodes = [], threeCatalogCodes = [], that = this;

          function setLstThirdCatagoryId(nodes) {
            for (const node of nodes) {
              // 查找当前节点是否在选中列表中
              const isSelected = that.queryParam.categoryList.some(selected => selected.id == node.id);

              if (isSelected) {
                if (node.level == 1) {
                  oneCategoryCode = [node.catalogCode];

                  // 有的一级分类下没有三级分类就获取二级分类的catalogCode；有三级分类就获取三级分类的catalogCode
                  // if (node.childList && Array.isArray(node.childList)) {
                  //   node.childList.forEach(child => {
                  //     let arr = [];
                  //     if (child.childList && Array.isArray(child.childList)) {
                  //       arr = child.childList.map(level2 => level2.catalogCode);
                  //       threeCatalogCodes.push(...arr)
                  //     } else {
                  //       twoCatalogCodes = node.childList.map(level2 => level2.catalogCode)
                  //     }
                  //   })
                  // }
                } else if (node.level == 2) {
                  twoCatalogCodes.push(node.catalogCode);

                  // 如果二级分类下有三级分类就获取三级分类的catalogCode；如果没有三级分类就获取二级分类的catalogCode
                  // if (node.childList && Array.isArray(node.childList)) {
                  //   threeCatalogCodes = node.childList.map(level2 => level2.catalogCode);
                  // } else {
                  //   twoCatalogCodes.push(node.catalogCode);
                  // }
                } else if (node.level == 3) {
                  threeCatalogCodes.push(node.catalogCode);
                }
              }

              if (node.childList && Array.isArray(node.childList)) {
                setLstThirdCatagoryId(node.childList);
              }
            }
          }

          setLstThirdCatagoryId(this.copyCategoryList)

          this.getData(oneCategoryCode, twoCatalogCodes, threeCatalogCodes);
        },

        async getData(oneCategoryCode = [], twoCatalogCodes = [], threeCatalogCodes = []){
          let id = this.$route.params.id;
          let pageSize = this.queryParam.pageSize;
          let query;

          if (id == 3) {  // 新品上市
            query = {
              page: this.queryParam.page,
              pageSize: pageSize,
            }
          } else if (id == 6) {  // 中药专区
            query = {
              page: this.queryParam.page,
              pageSize: pageSize,
              isBuys: this.queryParam.activity,  // 是否常购
            }
          } else if (id == 7) {  // 医保专区
            query = {
              page: this.queryParam.page,
              pageSize: pageSize,
              insuranceCode: this.insuranceCode ? this.insuranceCode : 0,
              isBuys: this.queryParam.activity,  // 是否常购
            }
            if (this.medicalClassSort === 1){
                query.medicalClassSort = this.medicalClassSort;//医保差价排序
            }
          } else if (id == 9) { // 诊卫专区
            query = {
              page: this.queryParam.page,
              pageSize: pageSize,
              isBuys: this.queryParam.activity,  // 是否常购
            }
            // query.isBuy = this.queryParam.activity;  // 是否常购
            query.medicalClass = this.medicalClass ? this.medicalClass : 0;  // 基药专区类型筛选 1基药+集采 2基药 3集采 4注射剂 5其他 6中成药（新增） 7口服液（新增）8学术（新增）
            // query.medicalClassSort = this.medicalClassSort;  // 基药专区排序 1.医保差价排序 2.周销量升序
          } else if (id == 10) {  // 独家专区
            query = {
              page: this.queryParam.page,
              pageSize: pageSize,
              isBuy: this.queryParam.activity  // 是否常购
            }
          } else if (id == 20) {  // 首页分类
            query = {
              page: this.queryParam.page,
              pageSize: pageSize,
            };
            if (oneCategoryCode.length) query.oneCategoryCodeList = oneCategoryCode; // 一级菜单id
            if (twoCatalogCodes.length) query.twoCategoryCodeList = twoCatalogCodes; // 二级菜单id
            if (threeCatalogCodes.length) query.threeCategoryCodeList = threeCatalogCodes; // 三级菜单id
          }
          if (this.queryParam.searchCriteria) query.param = this.queryParam.searchCriteria;  // 搜索条件
          const result = await this.$getHttpClient().post(this.pageAttr.url, query);
          if (result.data.code == '200') {
            let searchGoodList = purchaseQuantityProcessing(result.data.data.list);
            this.allGoodsList = searchGoodList;
            this.productList = searchGoodList;
            this.temporaryGoodList = this.productList;
            this.pageInfo.total = result.data.data.total;
            this.pageInfo.pageNum = result.data.data.pageNum;
          }
        },
        getDataByPage(data) {
          this.queryParam.page = data.pageNum;
          this.queryParam.activity = data.activity;
          this.queryParam.searchCriteria = data.searchCriteria;
          this.medicalClass = data.medicalClass;
          this.medicalClassSort = data.medicalClassSort;
          this.insuranceCode = data.insuranceCode;
          this.initData();
        }
    }
}
</script>
